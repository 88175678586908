<template>
  <div class="videobox">
    <iframe :src="src" ref="iframe" height="100%" width="100%" frameborder="0">
    </iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      src: ""
    };
  },
  mounted() {
    this.changesrc();
  },

  methods: {
    changesrc() {
      this.src = "/video2/index.htm?r=" + Math.random();
    }
  }
};
</script>
<style scoped>
.videobox {
  width: 100vw;
  height: 100vh;
}
</style>
